import { CompanyDocument } from './company-document.model';
import { SigningTemplate } from './signing-template.model';
import { SigningTemplateSlot } from './signing-template-slot.model';
import { SlotAssignment } from '@app/modules/document/classes';

/**
 * This is not a standard model.
 * It is not serializable.
 * It is used to encapsulate the idea of a document assignment.
 */
export class DocumentAssignment {
    /**
     * A HelloSign signature id given by HelloSign in the event
     * that a user edits a signing template during the
     * signature request assignment flow.
     *
     */
    editedSignatureRequestId: string | null = null;

    constructor(
        public companyDocument: CompanyDocument,
        public slotAssignments: SlotAssignment[] = []
    ) {}

    getSigningTemplate(): SigningTemplate {
        return this.companyDocument.signingTemplate;
    }

    getSigningTemplateSlots(): SigningTemplateSlot[] {
        return this.getSigningTemplate().slots;
    }

    hasTemplateBeenEdited(): boolean {
        return Boolean(this.editedSignatureRequestId);
    }

    isSigningDocument(): boolean {
        return this.companyDocument.isSigningDocument();
    }

    isDocumentAcknowledgement(): boolean {
        return this.companyDocument.isDocumentAcknowledgement();
    }

    isSingleSinger(): boolean {
        if (!this.isSigningDocument()) {
            return false;
        }

        return this.getSigningTemplateSlots().length === 1;
    }

    isMultiSigner(): boolean {
        if (!this.isSigningDocument()) {
            return false;
        }

        return this.getSigningTemplateSlots().length > 1;
    }

    hasNoSlotAssignmentsCompleted(): boolean {
        return this.slotAssignments.every((slot) => !slot.employee);
    }

    hasSlotAssignmentsCompleted(): boolean {
        return this.slotAssignments.every((slot) => slot.employee);
    }

    assignmentsInOrder(): SlotAssignment[] {
        const assigningOrder = this.slotAssignments.some((s: SlotAssignment) => s.order);

        if (!assigningOrder) {
            return this.slotAssignments.slice();
        }

        return this.slotAssignments
            .slice()
            .sort((a: SlotAssignment, b: SlotAssignment) => (assigningOrder ? a.order - b.order : 0));
    }
}
